<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005693')" prop="name">
              <Input :placeholder="$t('key1005694')" v-model.trim="pageParams.name" clearable></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005695')" prop="description">
              <Input :placeholder="$t('key1005696')" v-model.trim="pageParams.description" clearable></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005697')" prop="status">
              <Select v-model="pageParams.status">
                <Option value='All'>{{ $t('key1000095') }}</Option>
                <Option :value='1'>{{ $t('key1000507') }}</Option>
                <Option :value='0'>{{ $t('key1000508') }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <div class="mr15 sort_options_box">
        <span>{{ $t('key1002489') }}</span>
        <Button-group>
          <template v-for="(item,index) in buttonGroupModel">
            <Button v-if="item.selected" :key="index" type="primary" @click="modifyTheSort(index, item.status)">
              {{ item.title }}
              <Icon type="md-arrow-round-down" v-if="!item.status && item.selected"></Icon>
              <Icon type="md-arrow-round-up" v-if="item.status && item.selected"></Icon>
            </Button>
            <Button v-if="!item.selected" :key="index" @click="modifyTheSort(index, item.status)">
              {{ item.title }}
              <Icon type="md-arrow-round-up" v-if="item.status && item.selected"></Icon>
              <Icon type="md-arrow-round-down" v-if="!item.status && item.selected"></Icon>
            </Button>
          </template>
        </Button-group>
      </div>
      <Button type="primary" icon="md-add" @click="add" :disabled="!getPermission('productBrand_insert')">{{ $t('key1005701') }}</Button>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        highlight-row
        :loading="tableLoading"
        border
        max-height="550"
        :columns="tableColumns"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--新增编辑品牌模态窗-->
    <Modal v-model="productBrandModal" width="600" @on-ok="save" :title="productBrandModalTitle" class-name="roductBrandModalStyle"
      :transfer="false" :mask-closable="false" :loading="waitingProductBrandModalOk" @on-visible-change="productBrandModalChange">
      <Form ref="productBrandForm" :model="productBrand" :label-width="100" @submit.native.prevent>
        <Row type="flex" style="width: 85%">
          <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005693')" prop="name" :rules="{ required: true, message: $t('key1005692'), trigger: 'blur' }">
              <Input :maxlength="50" :placeholder="$t('key1005694')" v-model.trim="productBrand.name"></Input>
            </Form-item>
          </Col>
          <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005698')" prop="webSite">
              <Input :maxlength="100" :placeholder="$t('key1005699')" v-model.trim="productBrand.webSite"></Input>
            </Form-item>
          </Col>
          <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005695')" prop="description">
              <Input type="textarea" :autosize="{ minRows: 2,maxRows: 5 }" :maxlength="1000"
                :placeholder="$t('key1005696')" v-model.trim="productBrand.description">
              </Input>
            </Form-item>
          </Col>
          <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005697')" prop="status" class="radio_styles">
              <RadioGroup v-model="productBrand.status">
                <Radio :label="1">{{ $t('key1000507') }}</Radio>
                <Radio :label="0">{{ $t('key1000508') }}</Radio>
              </RadioGroup>
            </Form-item>
          </Col>
          <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005700')" prop="files">
              <Upload
                ref="uploadProductBrand"
                type="drag"
                name="files"
                :headers="headObj"
                :show-upload-list="false"
                :format="['jpg', 'png', 'gif']"
                :max-size="uploadFilesMaxSize"
                :on-exceeded-size="uploadExceedSize"
                :on-error="uploadFilesError"
                :on-success="uploadFilesSuccess"
                :on-format-error="handleFormatError"
                :action="uploadFiles">
                <div style="padding: 20px 20px">
                  <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                  <p>{{ $t('key1005702') }}</p>
                </div>
              </Upload>
              <div class="product-upload-list" v-for="(item, index) in uploadList" :key="index">
                <template v-if="item.status === 'finished'">
                  <img :src="item.url" class="img-center-style" alt="">
                  <div class="product-upload-list-cover">
                    <Icon type="ios-trash-outline" @click.native="removeFiles(item)"></Icon>
                  </div>
                </template>
                <template v-else>
                  <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                </template>
              </div>
            </Form-item>
          </Col>
        </Row>
      </Form>
      <template #footer style="text-align: center;">
        <Button @click="productBrandModal = false">{{ $t('key1000097') }}</Button>
        <Button type="primary" @click="save"> {{ $t('key1000096') }}</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {getUserInfoByUserIdsData, getUserNameByUserId} from "@/utils/common";

export default {
  name: "productBrand",
  mixins: [Mixin],
  data() {
    let self = this;
    return {
      operationType: 'add',
      total: 0, // 总记录数
      pageParamsStatus: false, // 是否重新加载品牌列表数据
      productBrandModal: false, // 品牌模型模态窗
      waitingProductBrandModalOk: true, // 点击确认按钮是否需要显示loading遮罩
      uploadList: [], // 已上传文件列表
      productBrandModalTitle: null, // 品牌模型模态窗口标题
      uploadFiles: api.post_uploadFiles + '?basePath=/productCenter/productBrand', // 上传logo的url
      filenodeViewTargetUrl: self.$store.state.erpConfig.filenodeViewTargetUrl, // filenode根路径
      pageParamsPrototype: null, // 当前货品信息原型(重置用到)
      pageParams: { // 品牌列表筛选参数
        status: null,
        description: null,
        name: null,
        orderBy: 'status',
        upDown: 'down',
        pageSize: 15,
        pageNum: 1
      },
      productBrand: { // 编辑、新增模型
        name: null,
        description: null,
        status: 1,
        logoPath: null,
        webSite: null
      },
      buttonGroupModel: [ // 排序按钮组
        {
          type: 'status',
          selected: true,
          status: false,
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005703')
        }, {
          type: 'name',
          selected: false,
          status: true,
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005704')
        }
      ],
      tableData: [], // 品牌数据
      tableColumns: [ // 品牌列定义
        {
          title: 'NO',
          width: 60,
          align: 'center',
          type: 'index',
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005705'),
          key: 'logoPath',
          width: 120,
          align: 'center',
          render: function (h, params) {
            return self.tableImg(h, params, 'logoPath');
          }
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005706'),
          key: 'name',
          align: 'center'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005707'),
          key: 'description',
          width: 250,
          align: 'center',
          render(h, params) {
            return self.checkContentOverflow(h, params.row.description, 250, 2);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005708'),
          key: 'webSite',
          align: 'center'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005709'),
          key: 'status',
          align: 'center',
          render(h, params) {
            return params.row.status === 0 ? h('span', {
              class: 'stopStatus'
            }, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000508')) : h('span', {
              class: 'openStatus'
            }, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000507'));
          }
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001577'),
          key: 'createdTime',
          align: 'center',
          sortable: true,
          render: (h, params) => {
            return h('span', self.$uDate.dealTime(params.row.createdTime, 'fulltime'));
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001576'),
          key: 'createdBy',
          align: 'center',
          render: (h, params) => {
            return h('div', getUserNameByUserId(params.row.createdBy, self));
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000278'),
          width: 180,
          fixed: 'right',
          align: 'center',
          render: function (h, params) {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000644'),
                    show: self.getPermission('productBrand_update'),
                    clickFn: () => {
                      self.edit(params.row);
                    }
                  },
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000508'),
                    show: params.row.status === 1 && self.getPermission('productBrand_disable'),
                    clickFn: () => {
                      self.enableDisableBtn(params.row.productBrandId, 'disable');
                    }
                  },
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000507'),
                    show: params.row.status === 0 && self.getPermission('productBrand_enable'),
                    clickFn: () => {
                      self.enableDisableBtn(params.row.productBrandId, 'enable');
                    }
                  },
                  {
                    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000091'),
                    show: self.getPermission('productBrand_delete'),
                    clickFn: () => {
                      self.del(params.row.productBrandId);
                    }
                  },
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    self.optionBtnWidth.push(value);
                    params.column.width = Math.max(...self.optionBtnWidth);
                    self.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ]
    };
  },
  created() {
    this.initFun();
  },
  mounted() {
    this.uploadList = this.$refs.uploadProductBrand.fileList;
  },
  methods: {
    // 初始化接口数据
    initFun() {
      (async () => {
        await this.getUserName();
        await this.search();
      })();
    },
    // 查询按钮
    search() {
      this.pageParams.pageNum = 1;
      this.pageParamsStatus = true;
    },
    // 重置按钮
    reset() {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.getList();
    },
    // 启用、停用按钮
    enableDisableBtn(productBrandId, type) {
      let v = this;
      let url = '';
      let text = '';
      if (type === 'disable') {
        url = api.put_productBrand_disable;
        text = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001797');
      } else {
        url = api.put_productBrand_enable;
        text = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001796');
      }
      v.axios.put(url + '?productBrandId=' + productBrandId, null, {loading: true, loadingText: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000212')}).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(text);
          v.pageParamsStatus = true;
        }
      });
    },

    // 删除按钮
    del(productBrandId) {
      let v = this;
      v.$Modal.confirm({
        title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005710'),
        onOk: () => {
          v.axios.delete(api.delete_productBrand + productBrandId).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000591'));
              v.search();
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    // 保存按钮
    save() {
      let self = this;
      let url = '';
      let method = '';
      let text = '';
      // 新增品牌
      if (self.operationType === 'add') {
        url = api.post_productBrand;
        text = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000501');
        method = 'POST';
      }
      // 编辑品牌
      else if (self.operationType === 'edit') {
        url = api.put_productBrand;
        text = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000690');
        method = 'PUT';
      }
      self.$refs['productBrandForm'].validate((valid) => {
        if (valid) {
          let query = {
            method: method,  // 请求的方法
            url: url,  // 接口
            data: self.productBrand
          };
          self.axios(query).then(response => {
            if (response.data.code === 0) {
              self.$Message.success(text);
              self.pageParamsStatus = true;
              self.resetProductBrandModalOk();
              self.productBrandModal = false;
            }
          });
        }
      });
    },
    // 新增品牌
    add() {
      this.productBrandModal = true;
      this.productBrandModalTitle = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005701');
      this.operationType = 'add';
    },
    // 编辑
    edit(editBrand) {
      let v = this;
      let {...copyBrand} = editBrand;
      v.productBrand = copyBrand;

      if (v.productBrand.logoPath) {
        v.uploadList.push({
          'name': v.productBrand.productBrandId,
          'status': 'finished',
          'url': v.filenodeViewTargetUrl + '/thumb' + v.productBrand.logoPath
        });
      }
      v.productBrandModal = true;
      v.productBrandModalTitle = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005711');
      v.operationType = 'edit';
    },
    // 上传文件成功
    uploadFilesSuccess(response, file, fileList) {
      let v = this;
      const url = file.response.datas[0];
      fileList.splice(0, fileList.length);
      file.url = v.filenodeViewTargetUrl + url;
      fileList.push(file);
      v.productBrand.logoPath = url;
    },
    removeFiles(file) { // 删除文件
      this.productBrand.logoPath = null;
      const fileList = this.$refs.uploadProductBrand.fileList;
      this.$refs.uploadProductBrand.fileList.splice(fileList.indexOf(file), 1);
    },
    // 查询列表数据
    getList() {
      let self = this;
      self.tableData = [];
      self.total = 0;
      let {...copyPageParams} = self.pageParams;
      if (copyPageParams.status === 'All') {
        copyPageParams.status = null;
      }
      self.tableLoading = true;
      self.axios.post(api.post_productBrand_query, copyPageParams).then(response => {
        let userIds = [];
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let list = data.list || [];
            if (list.length > 0) {
              list.forEach(function (n, i) {
                userIds.push(n.createdBy);
              });
              if (userIds.length > 0) {
                getUserInfoByUserIdsData(userIds,self).then(() => {
                  this.tableData = list;
                })
              }
            }
            self.$nextTick(() => {
              self.tableLoading = false;
            });
            self.total = Number(data.total);
          }
        }
      });
    },
    resetProductBrandModalOk() {
      let self = this;
      self.waitingProductBrandModalOk = false;
      self.$nextTick(function () {
        self.waitingProductBrandModalOk = true;
      });
    },
    // 监听弹窗
    productBrandModalChange(value) {
      if (!value) {
        this.$refs['productBrandForm'].resetFields();
        const fileList = this.$refs.uploadProductBrand.fileList;
        this.productBrand.status = 1;
        this.productBrand.logoPath = null;
        this.operationType = 'add';
        fileList.splice(0, fileList.length);
      }
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container_box {
  /deep/ .roductBrandModalStyle {
    .logo {
      display: flex;
      padding-left: 30px;

      .upload {
        margin-left: 10px;
      }
    }

    .radio_styles {
      .ivu-form-item-content label.ivu-radio-default {
        position: relative;
        top: -2px;
        font-size: 13px;
      }
    }
  }
}
</style>
